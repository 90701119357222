import { cm } from './cm.js';
import * as THREE from 'three';
// import Stats from 'three/examples/jsm/libs/stats.module';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { MeshObject, CanvasMesh, AnimationBoard } from './MeshObject.js';
import { KeyController } from './KeyController.js';
import { TouchController } from './TouchController.js';
import { Player } from './Player.js';
import * as CANNON from 'cannon-es';
import { Grass, Water } from './Grass.js';
import trees from './Trees.js';
import setGuardrails from './Guardrails.js';
import MessageWall from './MessageWall.js';
// import AnimationWall from './AnimationWall.js';
// import {
// 	getArtworks
// } from './Gallery.js';
import {
	ModalPanel
} from './ModalPanel.js';
import Navigation from './Navigation.js';

const modalPanel = ModalPanel({
	openCallback: () => {
		cm.modalOpened = true;
		setMode('website', false);
	},
	closeCallback: () => {
		cm.modalOpened = false;
		setMode('game');
	}
});

const appElem = document.getElementById('app');
appElem.addEventListener('click', e => {
	switch (e.target.dataset.function) {
		case 'close-panel':
			modalPanel.close();
			break;
	}
	
	e.stopPropagation();
});

const unit = cm.unit = 2;
const spawnX = cm.playerOrigin.x = 0*unit;
const spawnY = cm.playerOrigin.y = 0.6;
const spawnZ = cm.playerOrigin.z = 11*unit;
// cm.isNavigationOn = true;
const eyeLevel = 0.9; // 0.9

cm.notToActivateNavigation = false;
// cm.loading = document.querySelector('.loading');

const colors = cm.colors = {
	base: 'white',
	skin: '#edc69d',
	handle: 'white',
	// grass: '#33955b',
	grass: '#568D14',
	// water: '#62b2a3',
	water: '#54adbd',
	light: '#f7f4d5',
	// light: 'white',
	// sky: '#b2c7cb',
	sky: '#8cc2db',
	gBall: 'white',
	gBallText: '#00ee65',
	window: '#aed7e5',

	// stamp: '#2b0084',
	stamp: '#1f0084',
	text: '#454545',
	white: 'white',
	red: '#a50017',
	blue: '#5546ff',
	brown: '#a75942',
	pink: '#ff81cc',
	yellow: '#d4ff0a',
	orangeyellow: '#ffa94d',
	skyblue: '#70bde0',
	black: 'black',
	gray: '#454545',
	green: '#56718e',
	floor: '#bfaa9a'
};

// Renderer
const canvas = document.querySelector('#three-canvas');
const renderer = new THREE.WebGLRenderer({
	canvas,
	antialias: true,
	alpha: true
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio > 1 ? 2: 1);
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

// Scene
const scene = new THREE.Scene();
// scene.background = new THREE.Color(colors.sky);

scene.fog = new THREE.Fog(colors.sky, 15*unit, 25*unit);

// Camera
const camera = new THREE.PerspectiveCamera(
	60, // fov
	window.innerWidth / window.innerHeight, // aspect
	0.1, // near
	1000 // far
);
camera.position.set(0, 3, 7);
scene.add(camera);

// const controls = new OrbitControls(camera, renderer.domElement);
const textureLoader = new THREE.TextureLoader();
const gltfLoader = new GLTFLoader();
const keyController = new KeyController();
let touchController;
// const touchController = new TouchController(); // after 'load' to fix bug

// Light
const ambientLight = new THREE.AmbientLight(colors.light, 2.5);
scene.add(ambientLight);
const mainLight = new THREE.PointLight(colors.light, 500, 200);
mainLight.castShadow = true;
mainLight.shadow.mapSize.width = 2048;
mainLight.shadow.mapSize.height = 2048;
mainLight.position.set(-10, 20, 5);
scene.add(mainLight);

// Cannon(Physics)
const cannonWorld = new CANNON.World();
cannonWorld.gravity.set(0, -10, 0);

const defaultCannonMaterial = new CANNON.Material('default');
// const playerCannonMaterial = new CANNON.Material('player');

defaultCannonMaterial.friction = 1;
defaultCannonMaterial.rollingFriction = 1;
defaultCannonMaterial.restitution = 0;

const defaultContactMaterial = new CANNON.ContactMaterial(
	defaultCannonMaterial,
	defaultCannonMaterial,
	{
		friction: 1,
		rollingFriction: 1,
		restitution: 0,
		contactEquationStiffness: 1e7,
		contactEquationRelaxation: 3
	}
);

// const playerContactMaterial = new CANNON.ContactMaterial(
// 	playerCannonMaterial,
// 	defaultCannonMaterial,
// 	{
// 		friction: 1,
// 		restitution: 0
// 	}
// );

// cannonWorld.addContactMaterial(playerContactMaterial);
cannonWorld.addContactMaterial(defaultContactMaterial);
cannonWorld.defaultContactMaterial = defaultContactMaterial;

const cannonObjects = [];

// Mesh
const groundCenter = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'groundCenter',
	width: 13*unit,
	height: 0.1,
	depth: 13*unit,
	color: colors.grass,
	y: -0.5*(4.8) - 0.1,
});

const ground1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'ground1',
	width: 53*unit,
	height: 0.1,
	depth: 20*unit,
	color: colors.grass,
	y: -0.05,
	z: -16.5*unit
});

const ground2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'ground2',
	width: 20*unit,
	height: 0.1,
	depth: 13*unit,
	color: colors.grass,
	x: 16.5*unit,
	y: -0.05
});

const ground3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'ground3',
	width: 53*unit,
	height: 0.1,
	depth: 20*unit,
	color: colors.grass,
	y: -0.05,
	z: 16.5*unit
});

const ground4 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'ground4',
	width: 20*unit,
	height: 0.1,
	depth: 13*unit,
	color: colors.grass,
	x: -16.5*unit,
	y: -0.05
});

const grassCenter = new Grass({
	scene,
	unit,
	width: 13*unit,
	height: 13*unit,
	y: -0.5*(4.8),
	color: colors.grass
});

const grass1 = new Grass({
	scene,
	unit,
	width: 53*unit,
	height: 20*unit,
	z: -16.5*unit,
	color: colors.grass
});

const grass2 = new Grass({
	scene,
	unit,
	width: 20*unit,
	height: 13*unit,
	x: 16.5*unit,
	color: colors.grass
});

const grass3 = new Grass({
	scene,
	unit,
	width: 53*unit,
	height: 20*unit,
	z: 16.5*unit,
	color: colors.grass
});

const grass4 = new Grass({
	scene,
	unit,
	width: 20*unit,
	height: 13*unit,
	x: -16.5*unit,
	color: colors.grass
});

const floorCenter = cm.floorCenter = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter',
	width: 3*unit,
	height: 4.8 + 0.4,
	depth: 3*unit,
	y: -0.5*(4.8) + 0.2,
	// differenceY: '0'
});
// const floorCenter_top_body = new MeshObject({
// 	scene,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'floorCenter_top_body',
// 	width: 3*unit,
// 	height: 1,
// 	depth: 3*unit,
// 	y: 5,
// 	cannonOnly: true
// });
const floorCenter_body_1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_1',
	width: unit,
	height: 1,
	depth: 0.5,
	x: -unit,
	y: 0.9,
	z: -unit - unit/2,
	cannonOnly: true
});
const floorCenter_body_2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_2',
	width: unit,
	height: 1,
	depth: 0.5,
	x: unit,
	y: 0.9,
	z: -unit - unit/2,
	cannonOnly: true
});
const floorCenter_body_3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_3',
	width: 0.5,
	height: 1,
	depth: unit,
	x: unit + unit/2,
	y: 0.9,
	z: -unit,
	cannonOnly: true
});
const floorCenter_body_4 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_4',
	width: 0.5,
	height: 1,
	depth: unit,
	x: unit + unit/2,
	y: 0.9,
	z: unit,
	cannonOnly: true
});
const floorCenter_body_5 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_5',
	width: unit,
	height: 1,
	depth: 0.5,
	x: unit,
	y: 0.9,
	z: unit + unit/2,
	cannonOnly: true
});
const floorCenter_body_6 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_6',
	width: unit,
	height: 1,
	depth: 0.5,
	x: -unit,
	y: 0.9,
	z: unit + unit/2,
	cannonOnly: true
});
const floorCenter_body_7 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_7',
	width: 0.5,
	height: 1,
	depth: unit,
	x: -unit - unit/2,
	y: 0.9,
	z: unit,
	cannonOnly: true
});
const floorCenter_body_8 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floorCenter_body_8',
	width: 0.5,
	height: 1,
	depth: unit,
	x: -unit - unit/2,
	y: 0.9,
	z: -unit,
	cannonOnly: true
});

const floor1 = cm.floor1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor1',
	// color: colors.sky,
	width: 3*unit,
	height: 4.8 + 0.4,
	depth: 3*unit,
	y: -0.5*(4.8) + 0.2,
	z: -8*unit,
	// differenceY: '0'
});
const floor1_top_body = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor1_top_body',
	width: 3*unit,
	height: 1,
	depth: 3*unit,
	y: 5,
	z: -8*unit,
	cannonOnly: true
});
const floor1_body_1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor1_body_1',
	width: 3*unit,
	height: 4.2,
	depth: 0.5,
	z: -8*unit - unit - unit/2 + 0.2,
	cannonOnly: true
});
const floor1_body_2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor1_body_2',
	width: 0.5,
	height: 4.2,
	depth: 3*unit,
	x: unit + unit/2 - 0.2,
	z: -8*unit,
	cannonOnly: true
});
const floor1_body_3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor1_body_3',
	width: 0.5,
	height: 4.2,
	depth: 3*unit,
	x: -unit - unit/2 + 0.2,
	z: -8*unit,
	cannonOnly: true
});

const floor2 = cm.floor2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor2',
	width: 3*unit,
	height: 4.8 + 0.4,
	depth: 3*unit,
	x: 8*unit,
	y: -0.5*(4.8) + 0.2,
	// differenceY: '0'
});
const floor2_top_body = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor2_top_body',
	width: 3*unit,
	height: 1,
	depth: 3*unit,
	x: 8*unit,
	y: 5,
	cannonOnly: true
});
const floor2_body_1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor2_body_1',
	width: 3*unit,
	height: 4.2,
	depth: 0.5,
	x: 8*unit,
	z: -unit - unit/2 + 0.2,
	cannonOnly: true
});
const floor2_body_2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor2_body_2',
	width: 0.5,
	height: 4.2,
	depth: 3*unit,
	x: 9*unit + unit/2 - 0.2,
	cannonOnly: true
});
const floor2_body_3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor2_body_3',
	width: 3*unit,
	height: 4.2,
	depth: 0.5,
	x: 8*unit,
	z: unit + unit/2 - 0.2,
	cannonOnly: true
});
// const floor2_body_4 = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'floor2_body_4',
// 	width: 0.5,
// 	height: 4.2,
// 	depth: 1.5*unit - 0.5,
// 	x: 8*unit - 1.5*unit,
// 	z: -unit + 0.2,
// 	cannonOnly: true
// });
// const floor2_body_5 = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'floor2_body_5',
// 	width: 0.5,
// 	height: 4.2,
// 	depth: 1.5*unit - 0.5,
// 	x: 8*unit - 1.5*unit,
// 	z: unit - 0.2,
// 	cannonOnly: true
// });
// const floor2_body_6 = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'floor2_body_6',
// 	width: 0.5,
// 	height: 2.1,
// 	depth: 1,
// 	x: 8*unit - 1.5*unit,
// 	y: 3.5,
// 	cannonOnly: true
// });

const floor3 = cm.floor3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor3',
	width: 3*unit,
	height: 4.8 + 0.4,
	depth: 3*unit,
	x: -8*unit,
	y: -0.5*(4.8) + 0.2,
	// differenceY: '0'
});
const floor3_top_body = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor3_top_body',
	width: 3*unit,
	height: 1,
	depth: 3*unit,
	x: -8*unit,
	y: 5,
	cannonOnly: true
});
const floor3_body_1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor3_body_1',
	width: 3*unit,
	height: 4.2,
	depth: 0.5,
	x: -8*unit,
	z: -unit - unit/2 + 0.2,
	cannonOnly: true
});
const floor3_body_2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor3_body_2',
	width: 3*unit,
	height: 4.2,
	depth: 0.5,
	x: -8*unit,
	z: unit + unit/2 - 0.2,
	cannonOnly: true
});
const floor3_body_3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'floor3_body_3',
	width: 0.5,
	height: 4.2,
	depth: 3*unit,
	x: -9*unit - unit/2 + 0.2,
	cannonOnly: true
});

const roadEntrance = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'roadEntrance',
	width: 1*unit,
	height: 4.8 + 0.4,
	depth: 2*unit,
	y: -0.5*(4.8) + 0.2,
	z: 8*unit - unit/2,
	differenceY: '0'
});

const doorStep = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'doorStep',
	width: unit,
	height: 2,
	depth: 1,
	y: -0.265,
	z: 9*unit - 0.1,
	rotationX: THREE.MathUtils.degToRad(-80)
});

const road1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'road1',
	width: 1*unit,
	// height: 4.8 + 0.4,
	height: 0.3,
	depth: 4*unit,
	// y: -0.5*(4.8) + 0.2,
	y: 0.25,
	z: -3.5*unit,
	// differenceY: '0'
});

const road2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'road2',
	width: 4*unit,
	// height: 4.8 + 0.4,
	height: 0.3,
	depth: 1*unit,
	x: 3.5*unit,
	// y: -0.5*(4.8) + 0.2,
	y: 0.25,
	// differenceY: '0'
});

const road3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'road3',
	width: 1*unit,
	// height: 4.8 + 0.4,
	height: 0.3,
	depth: 4*unit,
	// y: -0.5*(4.8) + 0.2,
	y: 0.25,
	z: 3.5*unit,
	// differenceY: '0'
});

const road4 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'road4',
	width: 4*unit,
	// height: 4.8 + 0.4,
	height: 0.3,
	depth: 1*unit,
	x: -3.5*unit,
	// y: -0.5*(4.8) + 0.2,
	y: 0.25
});

const roadLong1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'roadLong1',
	width: 13*unit,
	height: 4.8 + 0.4,
	depth: 1*unit,
	y: -0.5*(4.8) + 0.2,
	z: -6*unit,
	// differenceY: '0'
});

const roadLong2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'roadLong2',
	width: 1*unit,
	height: 4.8 + 0.4,
	depth: 11*unit,
	x: 6*unit,
	y: -0.5*(4.8) + 0.2,
	// differenceY: '0'
});

const roadLong3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'roadLong3',
	width: 13*unit,
	height: 4.8 + 0.4,
	depth: 1*unit,
	y: -0.5*(4.8) + 0.2,
	z: 6*unit,
	// differenceY: '0'
});

const roadLong4 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'roadLong4',
	width: 1*unit,
	height: 4.8 + 0.4,
	depth: 11*unit,
	x: -6*unit,
	y: -0.5*(4.8) + 0.2,
	// differenceY: '0'
});

const water = new Water({
	scene,
	unit,
	width: 12.5*unit,
	height: 12.5*unit,
	color: colors.water,
	y: -0.3,
	intensity: 0.05,
	segment: 1,
	opacity: 0.7
});

const player = new Player({
	scene,
	camera,
	eyeLevel,
	colors,
	cannonWorld,
	// cannonMaterial: playerCannonMaterial,
	cannonMaterial: defaultCannonMaterial,
	loader: gltfLoader,
	x: spawnX,
	y: spawnY,
	z: spawnZ
});
cm.player = player;

const land = new MeshObject({
	scene,
	colors,
	// cannonWorld,
	// cannonMaterial: defaultCannonMaterial,
	name: 'land',
	loader: gltfLoader,
	y: -1,
	modelSrc: '/models/land.glb'
});

const messagePanel1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'message-panel-1',
	loader: gltfLoader,
	width: 0.6,
	height: 1.6,
	depth: 0.1,
	x: -1.2,
	y: 1,
	z: 9.3*unit,
	rotationY: Math.PI/4,
	modelSrc: '/models/message-panel1.glb'
});

const messagePanel2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'message-panel-2',
	loader: gltfLoader,
	width: 0.6,
	height: 1.6,
	depth: 0.1,
	x: 1.2,
	z: 7*unit - 0.5,
	rotationY: -Math.PI/4,
	modelSrc: '/models/message-panel3.glb'
});

const standBanner = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'standBanner',
	loader: gltfLoader,
	width: 0.8,
	height: 1.6,
	depth: 0.6,
	x: 2,
	z: -2,
	rotationY: -Math.PI/4,
	modelSrc: '/models/standbanner.glb'
});
// cannonObjects.push(standBanner);

const thisweekvideo = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'thisweekvideo',
	loader: gltfLoader,
	width: 0.8,
	height: 2,
	depth: 0.1,
	x: -2,
	z: -2,
	rotationY: Math.PI/4,
	modelSrc: '/models/thisweekvideo.glb',
	hasAnimation: true,
	callback() {
		const thisweekvideoThumbnail = new MeshObject({
			scene,
			colors,
			name: 'thisweekvideoThumbnail',
			loader: textureLoader,
			container: thisweekvideo.mesh,
			width: 0.8,
			height: 1.6,
			y: -0.1,
			z: 0.05,
			mapSrc: '/images/thisweekvideo/thisweekvideo3.png',
			geometry: new THREE.PlaneGeometry(0.8, 1.6)
		});
	}
});
// cannonObjects.push(thisweekvideo);

// const thisweekvideoThumbnail = new MeshObject({
// 	scene,
// 	colors,
// 	name: 'thisweekvideoThumbnail',
// 	loader: textureLoader,
// 	x: -2,
// 	z: -2,
// 	rotationY: Math.PI/4,
// 	mapSrc: '/images/thisweekvideo/1.png',
// 	geometry: new THREE.PlaneGeometry(0.8, 1.6)
// });

// gallery
const store1 = new MeshObject({
	scene,
	colors,
	// cannonWorld,
	// cannonMaterial: defaultCannonMaterial,
	name: 'store1',
	loader: gltfLoader,
	height: 6,
	z: -8*unit,
	modelSrc: '/models/store-onlinecourse.glb',
	callback() {
		const floorMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(unit*3 - 0.5, unit*3 - 0.5),
			new THREE.MeshLambertMaterial({color: colors.floor})
		);
		floorMesh.rotation.x = -Math.PI/2;
		floorMesh.position.z = 0.25;
		floorMesh.position.y = -2.99;
		floorMesh.receiveShadow = true;
		this.mesh.add(floorMesh);

		const animationBoard1 = new AnimationBoard({
			scene,
			container: store1.mesh,
			colors,
			name: 'animationBoard1',
			loader: textureLoader,
			textureImageFolder: '/models/textures/mural/1',
			textureStartIndex: 0,
			textureEndIndex: 13,
			intervalMS: 1000/6,
			textureImageExtension: 'png',
			geometry: new THREE.PlaneGeometry(2, 2),
			materialName: 'Standard',
			rotationY: -Math.PI/2,
			x: 2.48,
			y: -1.54,
			z: 2
		});
	}
});

const store2 = new MeshObject({
	scene,
	colors,
	// cannonWorld,
	// cannonMaterial: defaultCannonMaterial,
	name: 'store2',
	loader: gltfLoader,
	height: 6,
	x: 8*unit,
	modelSrc: '/models/store2.glb',
	rotationY: -Math.PI/2,
	callback() {
		const animationBoard2_1 = new AnimationBoard({
			scene,
			container: store2.mesh,
			colors,
			name: 'animationBoard2-1',
			loader: textureLoader,
			textureImageFolder: '/models/textures/mural/2',
			textureStartIndex: 0,
			textureEndIndex: 7,
			intervalMS: 1000/6,
			textureImageExtension: 'jpg',
			geometry: new THREE.PlaneGeometry(1.4, 1.4),
			materialName: 'Standard',
			rotationY: Math.PI/2,
			// x: 3.005,
			x: -2.48,
			y: -2.1,
			z: -0.1
		});
		
		const floorMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(unit*3, unit*3),
			new THREE.MeshLambertMaterial({color: colors.floor})
		);
		floorMesh.rotation.x = -Math.PI/2;
		floorMesh.position.y = -2.99;
		floorMesh.receiveShadow = true;
		this.mesh.add(floorMesh);
	}
});

const bicycle = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'bicycle',
	loader: gltfLoader,
	width: 1.6,
	height: 1,
	depth: 0.44,
	x: floor2.x,
	// y: 0.8,
	// z: floor2.z + 3.3,
	z: floor2.z - 2.2,
	modelSrc: '/models/bicycle.glb',
	mass: 10
});
cannonObjects.push(bicycle);

const store3 = new MeshObject({
	scene,
	colors,
	// cannonWorld,
	// cannonMaterial: defaultCannonMaterial,
	name: 'store3',
	loader: gltfLoader,
	height: 6,
	x: -8*unit,
	modelSrc: '/models/store-mural-girl.glb',
	rotationY: Math.PI/2,
	callback: () => {
		const animationBoard3 = new AnimationBoard({
			scene,
			container: store3.mesh,
			colors,
			name: 'animationBoard3',
			loader: textureLoader,
			textureImageFolder: '/models/textures/mural/3',
			textureStartIndex: 0,
			textureEndIndex: 11,
			textureImageExtension: 'png',
			geometry: new THREE.PlaneGeometry(3, 3),
			materialName: 'Standard',
			// rotationY: -Math.PI/2,
			// x: -3.005,
			y: -0.87,
			z: -2.49
		});
	}
});

// 우체통
const post = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'post',
	loader: gltfLoader,
	width: 0.4,
	height: 1.4,
	depth: 0.6,
	x: 1.3,
	y: 0.5,
	z: 9 * unit + 1,
	modelSrc: '/models/post.glb'
});

const shelfStore1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'shelfStore1',
	loader: gltfLoader,
	width: 1.8,
	height: 1.4,
	depth: 0.4,
	x: floor3.x - 0.5,
	z: floor3.z + 2,
	// rotationY: THREE.MathUtils.degToRad(90),
	modelSrc: '/models/shelf.glb',
	mass: 20
});
cannonObjects.push(shelfStore1);

const shelfStore2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'shelfStore2',
	loader: gltfLoader,
	width: 1.8,
	height: 1.4,
	depth: 0.4,
	x: floor3.x,
	z: floor3.z + 1.3,
	rotationX: THREE.MathUtils.degToRad(20),
	// rotationY: THREE.MathUtils.degToRad(-20),
	modelSrc: '/models/shelf.glb',
	mass: 20
});
cannonObjects.push(shelfStore2);

const deskChair3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'deskChair3',
	loader: gltfLoader,
	width: 0.64,
	height: 0.73,
	depth: 0.58,
	x: floor3.x + 1.5,
	z: floor3.z + 1.6,
	modelSrc: '/models/desk-chair.glb',
	mass: 5,
	rotationY: THREE.MathUtils.degToRad(40),
});
cannonObjects.push(deskChair3);

// const bed = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'bed',
// 	loader: gltfLoader,
// 	modelSrc: '/models/room-bed.glb',
// 	width: 1.6,
// 	height: 0.5,
// 	depth: 2.3,
// 	x: floor3.x - 1.5,
// 	z: floor3.z - 1.35,
// 	mass: 50
// });
// cannonObjects.push(bed);

// const pillow = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'pillow',
// 	loader: gltfLoader,
// 	modelSrc: '/models/room-bed-pillow.glb',
// 	width: 1,
// 	height: 0.2,
// 	depth: 0.5,
// 	// x: floor3.x + 1.7,
// 	// z: floor3.z - 2.05,
// 	x: floor3.x - 1.5,
// 	z: floor3.z - 2.1,
// 	y: 1,
// 	mass: 1,
// });
// cannonObjects.push(pillow);

const milestone = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'milestone',
	loader: gltfLoader,
	width: 0.2,
	height: 2,
	depth: 0.2,
	rotationY: THREE.MathUtils.degToRad(-3),
	modelSrc: '/models/milestone.glb'
});

const desk = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'desk',
	loader: gltfLoader,
	width: 2,
	height: 0.7,
	depth: 1,
	x: -1.05,
	z: floor1.z + 1.5,
	modelSrc: '/models/desk.glb'
});

const deskChair = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'deskChair',
	loader: gltfLoader,
	width: 0.64,
	height: 0.73,
	depth: 0.58,
	x: -1.5,
	z: floor1.z + 2.5,
	modelSrc: '/models/desk-chair.glb',
	mass: 5,
	rotationY: THREE.MathUtils.degToRad(-30)
});
cannonObjects.push(deskChair);

const deskChair2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'deskChair2',
	loader: gltfLoader,
	width: 0.64,
	height: 0.73,
	depth: 0.58,
	x: 2.15,
	z: floor1.z + 2,
	modelSrc: '/models/desk-chair.glb',
	mass: 5,
	rotationY: THREE.MathUtils.degToRad(180)
});
cannonObjects.push(deskChair2);

const codeDisplay = new MeshObject({
	scene,
	colors,
	// cannonWorld,
	// cannonMaterial: defaultCannonMaterial,
	name: 'codeDisplay',
	loader: gltfLoader,
	x: -0.4,
	y: 2.65,
	z: floor1.z - 2.42,
	// rotationY: Math.PI,
	modelSrc: '/models/display2.glb',
	callback: () => {
		const buildingProcessVideo = new AnimationBoard({
			scene,
			container: codeDisplay.mesh,
			colors,
			name: 'buildingProcessVideo',
			loader: textureLoader,
			textureImageFolder: '/models/textures/video/',
			textureStartIndex: 0,
			textureEndIndex: 1,
			textureImageExtension: 'jpg',
			geometry: new THREE.PlaneGeometry(3.1, 1.9), // 1.57:1
			materialName: 'Standard',
			y: 0.05,
			z: 0.03,
			intervalMS: 2000
		});
	}
});

// const pictureframe = new MeshObject({
// 	scene,
// 	colors,
// 	name: 'pictureframe',
// 	loader: gltfLoader,
// 	x: 1.8,
// 	y: 3.28,
// 	z: floor1.z - 2.49,
// 	modelSrc: '/models/pictureframe.glb'
// });

const shelf2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'shelf2',
	loader: gltfLoader,
	modelSrc: '/models/shelf2.glb',
	width: 3.3,
	height: 0.8,
	depth: 0.6,
	x: -0.4,
	z: floor1.z - 2.18
});

const flowerpot = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'flowerpot',
	loader: gltfLoader,
	modelSrc: '/models/flowerpot.glb',
	width: 0.6,
	height: 1.8,
	depth: 0.6,
	x: 1.8,
	z: floor1.z - 2.18,
	mass: 5
});
cannonObjects.push(flowerpot);

const course1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'course1',
	loader: gltfLoader,
	modelSrc: '/models/course1.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: -1.1,
	y: 1.3,
	z: floor1.z + 1.5,
	rotationX: THREE.MathUtils.degToRad(-20),
	// mass: 1,
});
// cannonObjects.push(course1);

const course1_1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'course1_1',
	loader: gltfLoader,
	modelSrc: '/models/course1.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: 0.3,
	y: 1.4,
	z: floor1.z - 2.1,
	rotationY: THREE.MathUtils.degToRad(45),
	mass: 1,
});
const course1_2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'course1_2',
	loader: gltfLoader,
	modelSrc: '/models/course1.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: 0.5,
	y: 1.4,
	z: floor1.z - 2.1,
	rotationY: THREE.MathUtils.degToRad(45),
	mass: 1,
});
const course1_3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'course1_3',
	loader: gltfLoader,
	modelSrc: '/models/course1.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: 0.7,
	y: 1.4,
	z: floor1.z - 2.1,
	rotationY: THREE.MathUtils.degToRad(45),
	mass: 1,
});
cannonObjects.push(course1_1, course1_2, course1_3);

const book1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'book1',
	loader: gltfLoader,
	modelSrc: '/models/book1.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: -1,
	y: 1.4,
	z: floor1.z - 2,
	rotationX: THREE.MathUtils.degToRad(90),
	rotationY: THREE.MathUtils.degToRad(-45),
	rotationZ: THREE.MathUtils.degToRad(40),
	mass: 1,
});
const book2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'book2',
	loader: gltfLoader,
	modelSrc: '/models/book2.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: -1.1,
	y: 1.7,
	z: floor1.z - 2.2,
	rotationX: THREE.MathUtils.degToRad(45),
	// rotationY: THREE.MathUtils.degToRad(20),
	// rotationZ: THREE.MathUtils.degToRad(-40),
	mass: 1,
});
const book3 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'book3',
	loader: gltfLoader,
	modelSrc: '/models/book3.glb',
	width: 0.3,
	height: 0.4,
	depth: 0.05,
	x: -1.3,
	y: 2,
	z: floor1.z - 2.25,
	rotationX: THREE.MathUtils.degToRad(45),
	rotationY: THREE.MathUtils.degToRad(70),
	rotationZ: THREE.MathUtils.degToRad(-10),
	mass: 1,
});
cannonObjects.push(book1, book2, book3);

const moreInfoCourse1 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'moreInfoCourse1',
	loader: gltfLoader,
	modelSrc: '/models/course-moreinfo.glb',
	width: 0.3,
	height: 0.38,
	depth: 0.3,
	x: -1.5,
	z: floor1.z + 1.5,
	y: 1.3
});

const laptop = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'laptop',
	loader: gltfLoader,
	width: 0.4,
	height: 0.28,
	depth: 0.36,
	x: -0.6,
	z: floor1.z + 1.5,
	y: 1.25,
	modelSrc: '/models/laptop.glb',
});

// // gallery stand
// const storeStand1 = new CanvasMesh({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'stand-gallery',
// 	loader: gltfLoader,
// 	width: 0.5,
// 	height: 1.2,
// 	depth: 0.4,
// 	z: floor1.z + 2.7,
// 	canvasWidth: 512,
// 	canvasHeight: 512,
// 	canvasTextureWidth: 0.4,
// 	canvasTextureHeight: 0.4,
// 	canvasY: 0.43,
// 	canvasRotationX: -Math.PI/4,
// 	modelSrc: '/models/info-stand-gallery.glb',
// 	canvasText: [
// 		`AI Gallery`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.text;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `28px "Roboto Condensed", sans-serif`;
// 		this.drawMultilineText(this.canvasText[0], 10, 30, 42);
// 		this.context.font = `bold 28px "Roboto Condensed", sans-serif`;
// 		this.drawMultilineText(this.canvasText[1], 10, 220, 42);
// 		this.context.font = `28px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[2], 10, 262, 42);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// // const storeStand2 = new CanvasMesh({
// // 	scene,
// // 	colors,
// // 	cannonWorld,
// // 	cannonMaterial: defaultCannonMaterial,
// // 	name: 'stand-shop',
// // 	loader: gltfLoader,
// // 	width: 0.5,
// // 	height: 1.2,
// // 	depth: 0.4,
// // 	x: floor2.x - 2.7,
// // 	z: floor2.z,
// // 	rotationY: -Math.PI/2,
// // 	canvasWidth: 512,
// // 	canvasHeight: 512,
// // 	canvasTextureWidth: 0.4,
// // 	canvasTextureHeight: 0.4,
// // 	canvasY: 0.43,
// // 	canvasRotationX: -Math.PI/4,
// // 	modelSrc: '/models/info-stand-gallery.glb',
// // 	canvasText: [
// // 		`AMAZON`
// // 	],
// // 	canvasDrawFunction: function() {
// // 		this.context.fillStyle = this.colors.text;
// // 		this.context.textBaseline = 'middle';
// // 		this.context.font = `20px "Roboto Condensed", sans-serif`;
// // 		this.drawMultilineText(this.canvasText[0], 0, 20, 32);
// // 	},
// // 	callback() {
// // 		if (this.textureSrc) {
// // 			this.setTexture();
// // 		}
// // 		if (this.canvasText) {
// // 			this.setCanvas();
// // 		}
// // 	}
// // });

// const storeStand3 = new CanvasMesh({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	name: 'stand-app',
// 	loader: gltfLoader,
// 	width: 0.5,
// 	height: 1.2,
// 	depth: 0.4,
// 	x: floor3.x + 2.7,
// 	z: floor3.z,
// 	rotationY: Math.PI/2,
// 	canvasWidth: 512,
// 	canvasHeight: 512,
// 	canvasTextureWidth: 0.4,
// 	canvasTextureHeight: 0.4,
// 	canvasY: 0.43,
// 	canvasRotationX: -Math.PI/4,
// 	modelSrc: '/models/info-stand-gallery.glb',
// 	canvasText: [
// 		`FOR RENT`
// 	],
// 	canvasDrawFunction: function() {
// 		console.log('draw!');
// 		this.context.fillStyle = this.colors.text;
// 		this.context.textAlign = 'center';
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 100px "Roboto Condensed", sans-serif`;
// 		// this.context.rotate(Math.PI/8);
// 		this.drawMultilineText(this.canvasText[0], 256, 256, 0);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 			console.log(111)
// 		}
// 	}
// });

// const artwork1 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-studiomeal',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	y: 1.8,
// 	z: floor1.z - 2.47,
// 	// canvasRotaionX: Math.PI/4,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'https://studiomeal.com',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const artwork2 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-2',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	x: floor1.x - 1.35,
// 	y: 1.8,
// 	z: floor1.z - 2.47,
// 	// canvasRotaionX: Math.PI/4,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'002',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const artwork3 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-3',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	x: floor1.x + 1.35,
// 	y: 1.8,
// 	z: floor1.z - 2.47,
// 	// canvasRotaionX: Math.PI/4,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'003',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const artwork4 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-4',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	x: floor1.x + 2.47,
// 	y: 1.8,
// 	z: floor1.z,
// 	rotationY: -Math.PI/2,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'004',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const artwork5 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-5',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	x: floor1.x + 2.47,
// 	y: 1.8,
// 	z: floor1.z - 1.35,
// 	rotationY: -Math.PI/2,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'005',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const artwork6 = new CanvasMesh({
// 	scene,
// 	colors,
// 	name: 'artwork-6',
// 	loader: gltfLoader,
// 	width: 0.8,
// 	height: 0.8,
// 	depth: 0.05,
// 	x: floor1.x + 2.47,
// 	y: 1.8,
// 	z: floor1.z + 1.35,
// 	rotationY: -Math.PI/2,
// 	modelSrc: '/models/picture-frame.glb',
// 	textureSrc: '/models/textures/works/DALL·E 2024-04-01 18.00.30 - A dystopian future where artificial intelligence dominates humans, depicted in an oil painting style. The scene shows towering, futuristic AI structur.webp',
// 	textureWidth: 0.76,
// 	textureHeight: 0.76,
// 	textureZ: 0.02,
// 	canvasWidth: 800,
// 	canvasHeight: 400,
// 	canvasTextureWidth: 0.8,
// 	canvasTextureHeight: 0.4,
// 	canvasY: -0.65,
// 	canvasText: [
// 		'006',
// 		'Junmo Yoo',
// 		'South Korea',
// 		`Creator's message:
// I am the owner of "Hey, You Did It!".
// This is my website for Koreans.`
// 	],
// 	canvasDrawFunction: function() {
// 		this.context.fillStyle = this.colors.gray;
// 		this.context.textBaseline = 'middle';
// 		this.context.font = `bold 30px "Roboto Condensed", sans-serif`;
// 		this.context.fillText(this.canvasText[0], 0, 20);
// 		this.context.fillText(`${this.canvasText[1]}, ${this.canvasText[2]}`, 0, 70);
// 		this.context.font = `30px "Roboto Condensed", sans-serif`;
// 		// (text, x, y, lineHeight)
// 		this.drawMultilineText(this.canvasText[3], 0, 120, 50);
// 	},
// 	callback() {
// 		if (this.textureSrc) {
// 			this.setTexture();
// 		}
// 		if (this.canvasText) {
// 			this.setCanvas();
// 		}
// 	}
// });

// const npcGallery = new MeshObject({
// 	scene,
// 	colors,
// 	cannonWorld,
// 	cannonMaterial: defaultCannonMaterial,
// 	loader: gltfLoader,
// 	name: 'npcGallery',
// 	width: 1,
// 	height: 1.8,
// 	depth: 0.6,
// 	x: floor1.x - 1.5,
// 	z: floor1.z + 2,
// 	// rotationY: THREE.MathUtils.degToRad(-120),
// 	rotationY: THREE.MathUtils.degToRad(30),
// 	modelSrc: '/models/npc-gallery.glb',
// 	hasAnimation: true
// });

const tableForMessageWall = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'tableForMessageWall',
	loader: gltfLoader,
	width: 0.6,
	height: 0.7,
	depth: 0.6,
	x: floor2.x - 2.5,
	z: floor2.z,
	modelSrc: '/models/laptop-table.glb'
});

const laptopForMessageWall = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'laptopForMessageWall',
	loader: gltfLoader,
	width: 0.4,
	height: 0.28,
	depth: 0.36,
	x: floor2.x - 2.5,
	y: 1.24,
	z: floor2.z,
	rotationY: -Math.PI/2,
	modelSrc: '/models/laptop2.glb'
});

// const animationWall1 = new AnimationWall({
// 	scene,
// 	colors,
// 	name: 'animationWall1',
// 	geometry: new THREE.PlaneGeometry(5, 3.6),
// 	x: floor2.x + 2.48,
// 	y: 2.2,
// 	z: floor2.z,
// 	rotationY: THREE.MathUtils.degToRad(-90),
// });

const messageWall = new MessageWall({
	scene,
	colors,
	name: 'messageWall1',
	geometry: new THREE.PlaneGeometry(5, 3.6),
	x: floor2.x + 2.49,
	y: 2.2,
	z: floor2.z,
	rotationY: THREE.MathUtils.degToRad(-90),
	modalPanel
});

const flowerpot2 = new MeshObject({
	scene,
	colors,
	cannonWorld,
	cannonMaterial: defaultCannonMaterial,
	name: 'flowerpot2',
	loader: gltfLoader,
	modelSrc: '/models/flowerpot.glb',
	width: 0.6,
	height: 1.8,
	depth: 0.6,
	x: floor2.x + 1,
	z: floor2.z + 2,
	mass: 5
});
cannonObjects.push(flowerpot2);

let device;
function setDevice() {
	const htmlElem = document.querySelector('html');
	if ('ontouchstart' in document.documentElement && window.innerWidth < 1300) {
		device = 'mobile';
		htmlElem.classList.add('touchevents');
	} else {
		device = 'desktop';
		htmlElem.classList.add('no-touchevents');
	}
}

function setLayout() {
	setDevice();
	if (device == 'mobile') {
		touchController.setPosition();
	}

	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize(window.innerWidth, window.innerHeight);
}

function move() {
	if (cm.modalOpened) return;
	
	if (keyController.keys['KeyW'] || keyController.keys['ArrowUp']) {
		// forward
		player.walk(-0.05, 'forward');
	}
	if (keyController.keys['KeyS'] || keyController.keys['ArrowDown']) {
		// backward
		player.walk(0.05, 'backward');
	}
	if (keyController.keys['KeyA'] || keyController.keys['ArrowLeft']) {
		// left
		player.walk(0.05, 'left');
	}
	if (keyController.keys['KeyD'] || keyController.keys['ArrowRight']) {
		// right
		player.walk(0.05, 'right');
	}
}

function moveMobile() {
	if (!touchController.walkTouch) return;

	const cx = touchController.cx;
	const cy = touchController.cy;
	const yy = touchController.walkTouch.clientY - cy;
	const xx = touchController.walkTouch.clientX - cx;
	const angle = Math.atan2(-yy, xx);
	const angle2 = Math.atan2(yy, xx);

	player.walkMobile(5*delta, angle);

	touchController.setAngleOfBar(angle2);
}

let movementX = 0;
let movementY = 0;
function updateMovementValue(event) {
	movementX = event.movementX*delta;
	movementY = event.movementY*delta;
	// console.log('x: ' + event.movementX);
	// console.log('y: ' + event.movementY);
}

const euler = new THREE.Euler(0, 0, 0, 'YXZ');
cm.euler = euler;
const minPolarAngle = 0;
const maxPolarAngle = Math.PI; // 180
// function moveCamera() {
// 	let factor = delta*10;
// 	if (device == 'mobile') {
// 		factor = delta*0.3;
// 	}

// 	// rotation
// 	euler.setFromQuaternion(camera.quaternion);
// 	euler.y -= movementX*factor;
// 	euler.x -= movementY*factor;
// 	euler.x = Math.max(Math.PI/2 - maxPolarAngle, Math.min(Math.PI/2 - minPolarAngle, euler.x));

// 	movementX -= movementX*0.2;
// 	movementY -= movementY*0.2;
// 	if (Math.abs(movementX) < 0.1) movementX = 0;
// 	if (Math.abs(movementY) < 0.1) movementY = 0;

// 	// camera.quaternion.setFromEuler(euler); // player.setRotation에서 처리
// 	player.setRotation(euler);

// 	// position
// 	camera.position.x = player.x;
// 	camera.position.y = player.y + eyeLevel;
// 	camera.position.z = player.z;
// }

// ChatGPT가 수정한 코드
function moveCamera() {
	let factor = delta * 10;
	if (device == 'mobile') {
			factor = delta * 0.3;
	}

	// rotation
	euler.setFromQuaternion(camera.quaternion);
	
	// 이전 프레임의 회전 값 저장
	const previousY = euler.y;
	const previousX = euler.x;

	// 새로운 회전 값 계산
	euler.y -= movementX * factor;
	euler.x -= movementY * factor;
	
	// 오일러 각도 클램핑
	euler.x = Math.max(Math.PI / 2 - maxPolarAngle, Math.min(Math.PI / 2 - minPolarAngle, euler.x));

	// 회전 값 변화량 제한
	const maxRotationChange = 0.1; // 회전 값 변화량의 최대 허용치 (적절한 값으로 조정)
	if (Math.abs(euler.y - previousY) > maxRotationChange) {
			euler.y = previousY + Math.sign(euler.y - previousY) * maxRotationChange;
	}
	if (Math.abs(euler.x - previousX) > maxRotationChange) {
			euler.x = previousX + Math.sign(euler.x - previousX) * maxRotationChange;
	}

	// movement 감속
	movementX -= movementX * 0.2;
	movementY -= movementY * 0.2;
	if (Math.abs(movementX) < 0.1) movementX = 0;
	if (Math.abs(movementY) < 0.1) movementY = 0;

	// camera.quaternion.setFromEuler(euler); // player.setRotation에서 처리
	player.setRotation(euler);

	// position
	camera.position.x = player.x;
	camera.position.y = player.y + eyeLevel;
	camera.position.z = player.z;
}

// 플레이어 player 회전시키기
// player.rotateToTarget({
// 	euler,
// 	x: floor2.x,
// 	z: floor2.z
// });

function setMode(mode, navigationOn) {
	if (device == 'mobile') {
		if (mode == 'game') {
			if (cm.isLoaded) {
				handAction(true);
				document.body.classList.remove('navigation-on');
			}
		} else if (mode == 'website') {
			if (navigationOn) {
				document.body.classList.add('navigation-on');
				if (cm.isLoaded) {
					setTimeout(handAction, 500);
				}
			} else {
				if (cm.isLoaded) {
					handAction(true);
					document.body.classList.remove('navigation-on');
				}
			}
		}
	} else {
		document.body.dataset.mode = mode;
		if (mode == 'game') {
			document.addEventListener('mousemove', updateMovementValue);
			canvas.requestPointerLock();
			if (cm.isLoaded) {
				handAction(true);
				document.body.classList.remove('navigation-on');
			}
		} else if (mode == 'website') {
			document.removeEventListener('mousemove', updateMovementValue);
			document.exitPointerLock();
			// if (cm.isNavigationOn) {
			if (navigationOn) {
				document.body.classList.add('navigation-on');
				if (cm.isLoaded) {
					setTimeout(handAction, 500);
				}
			} else {
				if (cm.isLoaded) {
					handAction(true);
					document.body.classList.remove('navigation-on');
				}
			}
		}
	}
}

// Raycasting
const mouse = new THREE.Vector2(); // 0, 0
const raycaster = new THREE.Raycaster();

function getDistance(a, b) {
	const distX = b.x - a.x;
	const distZ = b.z - a.z;
	const distance = Math.sqrt(distX*distX + distZ*distZ);
	return distance;
}

function checkIntersects() {
	player.attack();

	raycaster.setFromCamera(mouse, camera);

	let i = 0;

	const intersects = raycaster.intersectObjects(scene.children);
	// setTimeout(() => {
		for (const item of intersects) {
			// console.log(item.object.name);

			// force만 적용
			if (
				item.object.name.indexOf('book') >= 0 ||
				item.object.name.indexOf('deskChair') >= 0 ||
				item.object.name.indexOf('flowerpot') >= 0 ||
				item.object.name.indexOf('shelfStore') >= 0 ||
				item.object.name.indexOf('bicycle') >= 0 ||
				item.object.name.indexOf('pillow') >= 0 ||
				item.object.name.indexOf('bed') >= 0
			) {
				if (getDistance(player, item.object.position) > 3) return;
				player.force(cannonObjects.filter(obj => obj.name === item.object.name)[0]);
				break;
			}

			if (item.object.name.indexOf('course1') >= 0) {
				if (getDistance(player, item.object.position) > 3) return;

				if (item.object.name.indexOf('course1_') >= 0) {
					player.force(cannonObjects.filter(obj => obj.name === item.object.name)[0]);
				}

				setTimeout(() => {
					window.open('https://bit.ly/4guyDbG');
				}, 200);
				break;
			}

			if (item.object.name == 'standBanner') {
				if (getDistance(player, item.object.position) > 3) return;
				setTimeout(() => {
					window.open('https://bit.ly/4guyDbG');
				}, 200);
				break;
			}

			if (item.object.name.indexOf('thisweekvideo') >= 0) {
				if (getDistance(player, item.object.position) > 3) return;
				setTimeout(() => {
					// window.open('https://www.youtube.com/shorts/5_XHSOlrhQ8');
					// window.open('https://youtube.com/shorts/qfDGA4MeKI8');
					window.open('https://youtube.com/shorts/QhRNIwpe-8E?si=3RInrDhQsS107Pwn');
				}, 200);
				break;
			}

			if (item.object.name == 'codeDisplay') {
				if (getDistance(player, item.object.position) > 3) return;
				setTimeout(() => {
					window.open('https://bit.ly/4guyDbG');
				}, 200);
				break;
			}

			if (item.object.name == 'laptop') {
				if (getDistance(player, item.object.position) > 3) return;
				setTimeout(() => {
					window.open('https://www.youtube.com/@hey-you-did-it');
				}, 200);
				break;
			}

			if (item.object.name.indexOf('moreInfoCourse1') >= 0) {
				if (getDistance(player, item.object.position) > 5) return;

				setTimeout(() => {
					cm.notToActivateNavigation = true;
					setMode('website', false);
					modalPanel.open(`
						<figure class="content-image">
							<img style="width: 512px;" src="/images/moreinfo.jpg" alt="about course">
						</figure>
					`);
				}, 200);
				break;
			}
			
			// if (item.object.name == 'infoDisplay') {
			// 	if (getDistance(player, item.object.position) > 3) return;
			// 	console.log(item.object.name);
			// 	infoDisplay.resetCanvas();

			// 	if (infoDisplay.isReady) {
			// 		infoDisplay.isReady = false;
			// 	} else {
			// 		infoDisplay.isReady = true;
			// 	}
			// 	break;
			// }

			// if (item.object.name.indexOf('work') >= 0) {
			// 	if (getDistance(player, item.object.position) > 3) return;
			// 	console.log(item.object.name);
			// 	break;
			// }

			if (item.object.name.indexOf('laptopForMessageWall') >= 0) {
				if (getDistance(player, item.object.position) > 5) return;

				setTimeout(() => {
					cm.notToActivateNavigation = true;
					setMode('website', false);
					modalPanel.open(`
						<form id="message-wall-form" class="content-form">
							<div class="text-and-btn-group">
								<input type="text" id="message-wall-value" name="message" maxlength=30 placeholder="Message" required>
								<div class="btn-group">
									<button type="submit">OK</button>
									<button type="button" class="btn-cancel" data-function="close-panel">Cancel</button>
								</div>
							</div>
						</form>
					`,
					() => {
						const form = document.querySelector('#message-wall-form');
						document.querySelector('#message-wall-value').focus();
						form.addEventListener('submit', event => {
							event.preventDefault();
							document.body.classList.add('is-loading');
							messageWall.addMessage(document.getElementById('message-wall-value').value);
							modalPanel.close();
						});
					});
				}, 200);

				break;
			}

			// if (item.object.name == 'door') {
			// 	if (store2Door.opened) {
			// 		if (getDistance(player, item.object.position) > 3.5) return;
			// 	} else {
			// 		if (getDistance(player, item.object.position) > 2.8) return;
			// 	}

			// 	if (!store2Door.opened) {
			// 		if (!escapeRoom.started) store2Door.open();
			// 	} else {
			// 		store2Door.close();
			// 	}
			// }

			// if (item.object.name == 'npcGallery') {
			// 	if (getDistance(player, item.object.position) > 3) return;
			// 	root.render(GalleryRegistration());
			// 	break;
			// }

		}
	// }, 200);
}

// Draw
// Stats
// const stats = new Stats();
// document.body.append(stats.domElement);

const clock = new THREE.Clock();
let delta;
let time;
function draw() {
	// stats.update();
	delta = clock.getDelta();
	time = clock.getElapsedTime();

	let cannonStepTime = 1/60;
	if (delta < 0.01) cannonStepTime = 1/120;
	cannonWorld.step(cannonStepTime, delta, 3);

	// if (animationWall1) animationWall1.draw();

	for (const object of cannonObjects) {
		if (object.cannonBody) {
			object.mesh.position.copy(object.cannonBody.position);
			object.mesh.quaternion.copy(object.cannonBody.quaternion);
			if (object.transparentMesh) {
				object.transparentMesh.position.copy(object.cannonBody.position);
				object.transparentMesh.quaternion.copy(object.cannonBody.quaternion);
			}
		}
	}

	if (player.cannonBody) {
		player.mesh.position.copy(player.cannonBody.position);
		player.x = player.cannonBody.position.x;
		player.y = player.cannonBody.position.y;
		player.z = player.cannonBody.position.z;
		if (player.y > -0.4) {
			player.setWaterScreen(false);
			// player.waterScreen.visible = false;
		} else {
			player.setWaterScreen(true);
			// player.waterScreen.visible = true;
		}

		// playerLight.position.x = player.x;
		// playerLight.position.y = player.y + eyeLevel;
		// playerLight.position.z = player.z;

		if (device == 'mobile') {
			moveMobile();
		} else {
			move();
		}

		if (player.y < -10) {
			player.x = player.cannonBody.position.x = 0;
			player.y = player.cannonBody.position.y = spawnY;
			player.z = player.cannonBody.position.z = spawnZ;
		}
	}
	
	moveCamera();

	water.wave(clock.getElapsedTime()*3);

	// if (npcGallery.mixer) {
	// 	npcGallery.mixer.update(delta);
	// }

	if (thisweekvideo.mixer) {
		thisweekvideo.mixer.update(delta);
	}

	renderer.render(scene, camera);
	renderer.setAnimationLoop(draw);
}

function handAction(initializing) {
	const hand = document.querySelector('.hand');
	const handInner = document.querySelector('.hand-inner');
	const navigationContent = document.querySelector('.navigation-content');

	if (initializing) {
		hand.classList.add('initialized');
		navigationContent.classList.add('initialized');
	} else {
		hand.classList.remove('initialized');
		setTimeout(() => {
			navigationContent.classList.remove('initialized');
		}, 200);

		hand.animate(
			[
				{ transform: 'translate3d(100vw, 100vh, 0)' },
				{ transform: 'translate3d(0, 0, 0)' }
			],
			{
				duration: 180,
				fill: 'forwards'
			}
		);

		setTimeout(() => {
			navigationContent.animate(
				[
					{ clipPath: 'inset(0 0 100% 0)' },
					{ clipPath: 'inset(0 0 -120% 0)' }
				],
				{
					duration: 1300,
					easing: 'linear',
					fill: 'forwards'
				}
			);

			handInner.animate(
				[
					{ transform: 'translate3d(0, 0, 0)' },
					{ transform: 'translate3d(0, 3%, 0)' },
				],
				{
					duration: 50,
					easing: 'linear',
					iterations: Infinity,
					direction: 'alternate'
				}
			);

			hand.animate(
				[
					{ transform: 'translate3d(0, 0, 0)' },
					{ transform: 'translate3d(80vw, 20%, 0)' },
					{ transform: 'translate3d(0, 40%, 0)' },
					{ transform: 'translate3d(80vw, 60%, 0)' },
					{ transform: 'translate3d(0, 80%, 0)' },
					{ transform: 'translate3d(80vw, 100%, 0)' }
				],
				{
					duration: 1000,
					easing: 'linear',
					fill: 'forwards'
				}
			);
		}, 200);
	
	}

}

setDevice();
setMode('website', true);
// draw();
trees(scene, unit, colors, gltfLoader);
setGuardrails(scene, unit, colors, cannonWorld, defaultCannonMaterial);

window.addEventListener('load', () => {
	cm.navigation = new Navigation();
	cm.isLoaded = true;
	document.body.classList.remove('is-loading');

	touchController = new TouchController();
	setEvents();
	draw();

	handAction();
});

// Events
function setEvents() {
	window.addEventListener('resize', setLayout);

	document.addEventListener('click', () => {
		if (cm.isLoaded) {
			setMode('game');
		}
	});

	canvas.addEventListener('click', event => {
		if (device == 'mobile') {
			// mobile
			mouse.x = event.clientX / canvas.clientWidth*2 - 1;
			mouse.y = -(event.clientY / canvas.clientHeight*2 - 1);
			checkIntersects();
		} else {
			// desktop
			mouse.x = 0;
			mouse.y = 0;
			if (document.body.dataset.mode == 'game') {
				checkIntersects();
			}
		}
	});

	document.addEventListener('pointerlockchange', () => {
		if (document.pointerLockElement == canvas) {
			setMode('game');
		} else {
			if (cm.notToActivateNavigation) {
				setMode('website', false);
			} else {
				setMode('website', true);
			}
		}
	});

	window.addEventListener('keydown', event => {
		if (document.body.dataset.mode == 'game' && event.code == 'Space') {
			player.jump();
		}
	});

	document.querySelector('.btn-menu').addEventListener('click', e => {
		e.stopPropagation();
		setMode('website', true);
	});

	// TouchControl
	const touchX = [];
	const touchY = [];
	window.addEventListener('touchstart', event => {
		if (event.target == touchController.elem) return;
		movementX = 0;
		movementY = 0;

		touchX[0] = event.targetTouches[0].clientX;
		touchX[1] = event.targetTouches[0].clientX;
		touchY[0] = event.targetTouches[0].clientY;
		touchY[1] = event.targetTouches[0].clientY;
	});

	window.addEventListener('touchmove', event => {
		if (event.target == touchController.elem) return;

		movementX = 0;
		movementY = 0;

		touchX[0] = touchX[1];
		touchX[1] = event.targetTouches[0].clientX;
		touchY[0] = touchY[1];
		touchY[1] = event.targetTouches[0].clientY;

		movementX = touchX[1] - touchX[0];
		movementY = touchY[1] - touchY[0];
	});

	window.addEventListener('touchend', event => {
		if (event.target == touchController.elem) return;

		movementX = 0;
		movementY = 0;
		touchX[0] = touchX[1] = 0;
		touchY[0] = touchY[1] = 0;
	});

	window.addEventListener('gesturestart', event => {
		event.preventDefault();
	});
	window.addEventListener('gesturechange', event => {
		event.preventDefault();
	});
	window.addEventListener('gestureend', event => {
		event.preventDefault();
	});

}